<template>
  <div class="example-simple">

    <div class="upload">
      <ul>
        <li v-for="file in files" v-bind:key="file.name">{{file.name}} - Error: {{file.error}}, Success: {{file.success}}
        </li>
      </ul>
      <div class="example-btn">
        <form enctype="multipart/form-data" novalidate v-if="isInitial || isSaving">
          <div class="dropbox" id="welcome" data-v-step="welcome">
            <input type="file" :name="uploadFieldName" :disabled="isSaving"
              @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
              accept=".csv,.xls,.xlsx" class="input-file">
            <p v-if="isInitial">

              <span class="">
                <inline-svg width="50" height="50" src="media/svg/icons/General/upload_logo.svg" />
              </span>
              <br>
              <span class="file-upload-large-text">Drag to upload new file<br /></span>
              <span class="file-upload-small-text">or Click to Browse Files</span>
            </p>
            <p v-if="isSaving">
              Uploading {{ fileCount }} files...
            </p>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<style>
.example-simple label.btn {
  margin-bottom: 0;
  margin-right: 1rem;
}
</style>



<script>

import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

export default {
  components: {
  },
  data() {
    return {
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: 'taskfile'
    }
  },
  mounted() {
    this.reset();
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    }
  },
  methods: {

    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
    },

    filesChange(fieldName, fileList) {
      // handle file changes
      const formData = new FormData();
      if (!fileList.length) return;
      // append the files to FormData
      var cnt = 0;
      Array
        .from(Array(fileList.length).keys())
        .map(x => {
          if (fileList[x].type.match('text/csv') ||
            fileList[x].type.match('application/vnd.ms-excel') ||
            fileList[x].type.match('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
            formData.append(fieldName, fileList[x], fileList[x].name);
            cnt++;
          }
          else {
            Swal.fire({
              icon: 'error',
              title: "ERROR",
              text: fileList[x].name + ' Not Supported,  Only Supported Format CSV,XLS,XSLx',
              allowOutsideClick: false,
              // onOpen: () => {          Swal.showLoading();         }
            });
          }
        });
      // save it

      if (cnt > 0) {
        this.save(formData);
      }

      return;
    },
    save(formData) {
      // upload data to the server
      this.currentStatus = STATUS_SAVING;
      this.upload(formData);
    },

    upload(formData) {

      /*
        Make the request to the POST /select-files URL
      */
      Swal.fire({
        title: "Please Wait",
        text: "Your data is being securely uploaded. This usually takes a couple of seconds, however if you have a large file this could take a couple of minutes.",
        allowOutsideClick: false,
        onOpen: () => { Swal.showLoading(); }
      })

      ApiService.setHeader();
      ApiService.post("/app/task/upload",
        formData)
        .then(() => {
          this.currentStatus = STATUS_INITIAL;
          this.uploadedFiles = [];
          this.uploadError = null;
          this.$emit('uploadFinished');
          Swal.close();
        })
        .catch(() => {
          Swal.close();
        });

    },

    /**
     * Pretreatment
     * @param  Object|undefined   newFile   Read and write
     * @param  Object|undefined   oldFile   Read only
     * @param  Function           prevent   Prevent changing
     * @return undefined
     */
    inputFilter: function (newFile, oldFile, prevent) {

      if (newFile && !oldFile) {

        // if (!/\.(txt|csv|xls|xlsx)$/i.test(newFile.name))
        if (!/\.(csv|xls|xlsx)$/i.test(newFile.name)) {
          return prevent()
        }
      }


      let formData = new FormData();
      /*
        Iteate over any file sent over appending the files
        to the form data.
      */
      for (var i = 0; i < this.files.length; i++) {
        let file = this.files[i].file;
        formData.append('taskfile', file);
      }






    }
  }
}
</script>




<style>
.dropbox {
  border: 2px dashed rgba(59, 184, 134, 0.56);
  background-color: transparent !important;
  color: #3f6f86 !important;
  position: relative;
  cursor: pointer;
  border-radius: 16px;
  margin-top: -15px;
}

.input-file {
  opacity: 0;
  /* invisible but it's there! */
  width: 100%;
  height: 150px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue;
  /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 10px 0 10px;
}


.file-upload-large-text {


  width: 225px;
  height: 22px;
  left: 529px;
  top: 86px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  /* identical to box height, or 110% */
  letter-spacing: -0.408px;

  color: #3D415B;
}

.file-upload-small-text {
  width: 159px;
  height: 22px;
  left: 562px;
  top: 112px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height, or 147% */
  letter-spacing: -0.408px;

  color: #525575;
}
</style>