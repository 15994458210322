let valueDisplays=document.querySelectorAll("totalRecord-Count")
let interval=5000;

valueDisplays.forEach((valueDisplay) => {
    let startValue = 0;
    let endValue = parseInt(valueDisplays.getAttribute
        ("data-val"));
        let duration = Math.floor(interval/endValue);
        let counter = setInterval(function(){
            startValue += 1;
            valueDisplay.textContent = startValue;
            if(startValue == endValue){
                clearInterval(counter);

            }
        }, duration);
});
