<template>
  <div id="" :key="dashboard_reload">
    <div v-if="!showCurrentTask">
      <div class="card card-custom gutter-b card-stretch">
        <file-section @uploadFinished="uploadFinished"></file-section>
      </div>

      <Statistics :currentTasks="currentTasks"></Statistics>

      <div class="row m-2">
        <br />
        <div class="col-md-3 allfiles d-flex align-items-center" id="length">
          <span>All files</span>
          <!-- <div id="">
            <span> </span>
          </div> -->
        </div>
        <div class="col-md-3"></div>
        <div class="col-md-3"></div>
        <div class="col-md-3 float-right">
          <div class="float-right align-center">
            <center>
              <button id="refresh" @click="refreshScreen()">
                <inline-svg width="30" height="30" src="media/svg/icons/General/Refresh.svg" />
              </button>
            </center>
          </div>
        </div>
      </div>

      <div class="dummy_table-container overflow-auto">
        <div class="dummy_scroll-content">
          <b-table no-border-collapse hover :items="currentTasks" :fields="fields" :head-class="''"
            head-row-class="table-header" sort-icon class="task-list custom-b-table-class"
            @row-clicked="myRowClickHandler" custom-row-key="id">
            <template v-slot:head()="{ label } ">
              <div class="text-nowrap ">
                {{ label }}
              </div>
            </template>
            <template #cell(created_at)="data">
              <div class="text-center">
                <span>{{ formatDate(data.item.created_at) }}</span>
              </div>
            </template>

            <template #cell(file_size)="data">
              <!-- <div class="text-center">{{ data.item.file_size }} MB</div> -->
              <div class="text-center">{{ data.item.file_size }}</div>
            </template>
            <!-- 
            <template #cell(estprice)="data">
              <div class="text-center">
                {{ data.item.estprice }}
              </div>
            </template> -->

            <template #cell(progress_status)="data">
              <div class="text-left">
                <span v-bind:style="{ color: getStatusColor(data.item) }">
                  {{ getStatus(data.item) }}</span>
              </div>
            </template>

            <template #cell(icon)="data">
              <div class="text-center">
                <span v-if="data.item.stage == 'Archived'" text-center>
                  <inline-svg width="25" height="25" src="media/svg/icons/General/File_Delete.svg" />
                </span>
                <span v-else-if="data.item.stage == 'Started'">
                  <inline-svg width="25" height="25" src="media/svg/icons/General/File_Started.svg" />
                </span>
                <span v-else-if="data.item.stage == 'Paid'">
                  <inline-svg width="25" height="25" src="media/svg/icons/General/File_Paid.svg" />
                </span>
                <span v-else-if="data.item.stage == 'Finished'">
                  <inline-svg width="25" height="25" src="media/svg/icons/General/File_Ready.svg" />
                </span>
                <span v-else-if="data.item.stage == 'Matched'">
                  <inline-svg width="25" height="25" src="media/svg/icons/General/File_Import.svg" />
                </span>
                <span v-else-if="data.item.stage == 'Processing'">
                  <inline-svg width="25" height="25" src="media/svg/icons/General/File_Process.svg" />
                </span>
                <span v-else> no-icon </span>
              </div>
            </template>

            <template #cell(title)="data">
              <div :data-v-step="data.item.id" class="border-right-0">
                &nbsp;
                <!-- <span v-bind:style="{ color: getStatusColor(data.item) }">{{
                  data.item.title
                  }}</span> -->
                <span>{{ data.item.title }}</span>
              </div>
            </template>

            <template #cell(id)="data">
              <span>{{ data.item.id }}</span>
            </template>

            <template #cell(actions)="data">
              <div class="text-center">
                <button v-if="data.item.stage != 'Archived'" @click="deleteTag(data.item.id, 'trashed', 'true')">
                  <inline-svg src="media/svg/icons/General/trash_icon.svg" />
                </button>
              </div>
            </template>
          </b-table>
        </div>
      </div>

      <div v-if="currentTasks.length > 0">
        <p v-if="filter != ''">
          <!-- The table is filtered by the tag : {{ filter }} -->
        </p>
      </div>
      <div v-if="currentTasks.length == 0">
        <p v-if="filter != ''">
          <!-- No results found for the filter : {{ filter }} -->
        </p>
      </div>
    </div>

    <div v-if="currentTasks.length == 0" class="row m-1">
      <div class="col-md-8 mx-auto">
        <div class="font-size-h1 font-weight-bold text-center">
          No files have been uploaded. Please click or drag and drop a file
          above to get started.
        </div>
      </div>
    </div>

    <div v-if="showCurrentTask">
      <b-row class="Dashboard-HeaderRow">
        <b-col class="Dashboard-Go-Back dashboarmapp-left-pad" col lg="2" @click="importGoback">Go Back</b-col>
        <b-col class="Dashboard-Readr-Campaign-Dataxls"><v-icon large style="font-size: 38px">mdi-file-excel</v-icon>{{
          currentTask.title }}</b-col>
      </b-row>

      <b-row>
        <b-col class="dashboarmapp-left-pad">
          <p class="Dashboard-Map-your-Data">Map your Data</p>
          <p class="Dashboard-Map-your-Text">
            Please can you check the mapping below is correct and then
            <br />press the 'Finish mappinng your fields to continue' button. We
            can then start the cleaning of your data.
          </p>
        </b-col>
        <b-col>
          <p class="Dashboard-Map-your-Data">
            <center><br />Unmapped Fields</center>
          </p>
        </b-col>
        <b-col>
          <button type="reset" class="btn btn-success mr-2 dashboard-action-button" @click="save()" ref="kt_save_changes">
            Finish mapping your fields to continue
          </button>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="dashboarmapp-left-pad">
          <b-table hover :items="fieldData" :fields="matchingFields" class="field-match-list">
            <template #cell(text)="data">
              <div class="dashboard-data-field-text-holder">
                {{ data.item.text }}
              </div>
            </template>
            <template #cell(matchFlag)="data">
              <div class="dashboard-data-field-tick-rholder">
                <v-icon small v-if="mappings[data.index] == 0"
                  class="dashboard-data-field-tick-icon-fail">mdi-help-circle</v-icon>
                <v-icon small v-if="mappings[data.index] != 0"
                  class="dashboard-data-field-tick-icon-success">mdi-check-circle</v-icon>
              </div>
            </template>

            <template #cell(lookupField)="data">
              <select @change="onChangeSelection(data.index)" v-model="mappings[data.index]" class="
                                                                              dashboard-data-field-text-holder dashboard-showtick-dropdown
                                                                            ">
                <option v-for="(lookupField, i) in lookupFields" :value="i" style="color: black" v-bind:key="i">
                  {{ lookupField }}
                </option>
              </select>
            </template>
            <template #cell(sample1)="data">
              <div class="dashboard-data-field-sample">
                {{ showDataField(data.index, 1) }}
              </div>
            </template>
            <template #cell(sample2)="data">
              <div class="dashboard-data-field-sample">
                {{ showDataField(data.index, 2) }}
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <vs-table :data="fieldData" style="background-color: #fff">
        <template slot="thead">
          <vs-th>File Column</vs-th>
          <vs-th>Map to</vs-th>
          <vs-th>Record {{ startRecord + 1 }}</vs-th>
          <vs-th>Record {{ startRecord + 2 }}</vs-th>
          <vs-th>Record {{ startRecord + 3 }}</vs-th>
        </template>
        <vs-tr class="tr-values vs-table--tr tr-table-state-null" v-for="(field, fIndex) in fieldData"
          :key="'headings' + fIndex">
          <vs-td>
            {{ field.text }}
          </vs-td>
          <vs-td> </vs-td>
          <vs-td> </vs-td>
          <vs-td>
            {{ showDataField(fIndex, 2) }}
          </vs-td>
          <vs-td>
            {{ showDataField(fIndex, 3) }}
          </vs-td>
        </vs-tr>
      </vs-table>
    </div>

    <!-- :steps="tourSteps" -->
    <!-- :steps="steps" -->
    <template>
      <v-tour name="onboarding-tour" :steps="steps" :options="myOptions">
        <template slot-scope="tour">
          <transition name="fade">
            <v-step v-if="tour.steps[tour.currentStep]" :key="tour.currentStep" :step="tour.steps[tour.currentStep]"
              :previous-step="tour.previousStep" :next-step="tour.nextStep" :stop="tour.stop" :is-first="tour.isFirst"
              :is-last="tour.isLast" :labels="tour.labels" :highlight="tour.highlight">
              <template>
                <!-- v-if="tour.currentStep === 0" -->
                <div slot="actions" class="flex items-center justify-center">
                  <button @click="tour.skip" class="v-step__button-skip">
                    Skips
                  </button>
                  <button @click="tour.finish">
                    OK
                  </button>
                </div>
              </template>
            </v-step>
          </transition>
        </template>
      </v-tour>
    </template>
  </div>
</template>

<script>
import FileSection from "@/view/content/widgets/FileSection.vue";

import { mapGetters } from "vuex";
import { mapMutations } from "vuex";

import { UPDATE_TASKS, UPDATE_TAG } from "@/core/services/store/tasks.module";
import { VERIFY_AUTH, UPDATE_USER_SETTING } from "@/core/services/store/auth.module";
import { SET_RIGHT_ASIDE } from "@/core/services/store/right_aside.module";
import Statistics from "@/view/pages/DashboardStatistics.vue";
import Swal from "sweetalert2";

import { useShepherd } from "vue-shepherd";

require("shepherd.js/dist/css/shepherd.css");

// const tour = useShepherd({
//   useModalOverlay: true,
// });

export default {
  name: "dashboard",
  components: {
    FileSection,
    Statistics,
  },
  props: ["helpValue", "refreshValue"],
  watch: {
    helpValue: function () {
      // watch it
      this.startTour(true);
    },
    refreshValue: function () {
      this.tagclick("");
    },
  },
  data() {
    return {
      myOptions: {
        useKeyboardNavigation: false,
        highlight: true,
        labels: {
          buttonSkip: "Skip",
          buttonPrevious: "Previous",
          buttonNext: "Next",
          buttonStop: "Finish",
        },
      },
      tourSteps1: [],
      stepsCount: 100,
      steps: Array(100).fill({}),
      // Note 'isActive' is left out and will not appear in the rendered table      
      filter: "",
      jsonData: {},
      flag: false,
      currentTask: null,
      polling: null,
      showCurrentTask: false,
      dashboard_tour: false,
      //vue-shephered variabale
      tour: null,
      matchingFields: [
        {
          key: "text",
          label: "Your Column Fields",
        },
        {
          key: "matchFlag",
          label: "",
        },
        {
          key: "lookupField",
          label: "Mapped Output",
          tdClass: "dashboard-data-field-tick-right-box",
        },
        {
          key: "sample1",
          label: "Sample 1",
          tdClass: "dashboard-data-field-tick-example-data",
        },
        {
          key: "sample2",
          label: "Sample 2",
          tdClass: "dashboard-data-field-tick-example-data",
        },
      ],
      fields: [
        {
          key: "icon",
          label: "",
          sortable: false,
          thClass: "text-center ",
          tdClass: "align-middle",
          thStyle: { width: "4%", },
        },
        {
          key: "title",
          label: "File Name",
          sortable: true,
          thClass: "text-left ",
          tdClass: "align-middle",
          thStyle: { width: "36%" },
        },
        {
          key: "id",
          label: "ID",
          thClass: "d-none",
          tdClass: "d-none",
          thStyle: { width: "0%" },
        },
        {
          key: "progress_status",
          label: "File Status",
          sortable: true,
          thClass: "text-left tableheads",
          tdClass: "align-middle",
          thStyle: { width: "30%" },
        },
        {
          key: "file_size",
          label: "Record Count",
          sortable: true,
          thClass: "text-center tableheads",
          tdClass: "align-middle",
          thStyle: { width: "13%" },
        },
        // {
        //   key: "estprice",
        //   label: "Price Estimate",
        //   sortable: true,
        //   thClass: "text-center tableheads",
        //   tdClass: "align-middle",
        //   thStyle: { width: "10%" },
        // },
        {
          key: "created_at",
          label: "Uploaded At",
          sortable: true,
          thClass: "text-center tableheads",
          tdClass: "align-middle",
          thStyle: { width: "12%" },
        },
        //  {
        //    key: 'tags',
        //    label: 'Tags',
        //  },
        {
          key: "actions",
          label: "Action",
          thClass: "text-center tableheads",
          tdClass: "align-middle",
          thStyle: { width: "5%" },
        },
      ],
      lookupFields: [
        "Unmapped",
        "URN",
        "Title",
        "Initial",
        "First Name",
        "Surname",
        "Company Name",
        "Address line 1",
        "Address line 2",
        "Address line 3",
        "Town",
        "County",
        "Postcode",
        "Telephone 1",
        "Telephone 2",
        "Telephone 3",
        "Email Address 1",
        "Email Address 2",
        "Email Address 3",
      ],
    };
  },
  mounted() {

    // redirect to match or review link from mail
    if (Object.keys(this.$route.params).length > 0) {
      var page = '';
      var pageid = '';
      page = this.$route.params.id.toString();
      if (page.includes('match')) {
        pageid = page.substring(6);
        page = page.substring(0, 5);

        this.$router.push({
          name: "dashboardmatch",
          params: { id: pageid },
        });

      } else if (page.includes('review')) {
        pageid = page.substring(7);
        page = page.substring(0, 6);

        this.$router.push({
          name: "dashboardreview",
          params: { id: pageid },
        });

      }
      else {
        page = '';
        pageid = '';
      }
    }

    if (this.tour != null) {
      if (this.tour.steps.length >= 0) {
        this.tour.steps.forEach((step) => {
          this.tour.removeStep(step.id);
          // step.destroy();
        });
      }
    }

    this.tour = useShepherd({ useModalOverlay: true, }),

    this.getData(this.filter, "");
  },
  created() {
    this.pollData();
  },
  updated() {
    if (this.tour.steps.length == 0) {
      setTimeout(() => {
        this.startTour();
      }, 2000);
    }
  },
  beforeDestroy() {
    clearInterval(this.polling);

    if (this.tour.steps.length >= 0) {
      this.tour.off("cancel");
      this.tour.off("complete");
      this.tour.complete();
    }

    // // remove event
    // tour.off("cancel", this.turnOffTour);
    // tour.off("hide", this.tourHide);

    // // remove all steps
    // if(tour.steps.length >=0 ){
    //   tour.steps.forEach((step) => {
    //     tour.removeStep(step.id);
    //       // step.destroy();
    //   });
    // }    

  },
  destroy() {
    // remove all steps
    for (var i = 0; i < 5; i++) {
      if (this.tour.steps.length >= 0) {
        this.tour.steps.forEach((step) => {
          this.tour.removeStep(step.id);
          // step.destroy();
        });
      }
    }
  },
  methods: {

    turnOffTour() {
      if (
        this.currentUser.userData.onboarding_flag == "1" ||
        this.currentUser.userData.onboarding_flag == true
      ) {
        var startHelp = document.getElementById("startHelpRef");
        startHelp.click();
      }
    },
    tourComplete() {
      //nothing todo here, just hide the current onboarding when OK button clicked

      // var onboardingFlag = this.$store.getters.currentUser.userData.onboarding.substr(0, 1);
      // var onboardingStep = this.$store.getters.currentUser.userData.onboarding.substr(2, 5);
      // var heatmap        = this.$store.getters.currentUser.userData.heatmap;
      // var onboarding_flag= this.$store.getters.currentUser.userData.onboarding_flag;
      // if(onboarding_flag == 1 && onboardingFlag == "N" && ( onboardingStep == 1 || onboardingStep == 2 || onboardingStep == 3 || onboardingStep == 5 || onboardingStep == 6)) {
      //     onboardingStep = parseInt(onboardingStep) + 1;

      //     var onboarding =  onboardingFlag + "-" + onboardingStep;

      //     this.$store.dispatch(
      //           UPDATE_USER_SETTING,
      //           { onboarding, heatmap, onboarding_flag },
      //           this.$store.getters.currentUser.userData.id
      //     );

      //     this.$store.commit('setUserSetting',{ onboarding: onboarding, heatmap: heatmap, onboarding_flag: onboarding_flag });
      // }

    },
    startTour() {

      // this.$tours["onboarding-tour"].stop();

      var onboarding_flag = false;
      if (
        this.currentUser.userData.onboarding_flag == "1" ||
        this.currentUser.userData.onboarding_flag == true
      ) {
        onboarding_flag = true;
      }

      // var onboardingFlag = this.$store.getters.currentUser.userData.onboarding.substr(0, 1);
      var onboardingStep = this.$store.getters.currentUser.userData.onboarding.substr(2, 5);

      //event fire off
      this.tour.off("cancel");
      this.tour.off("complete");

      // remove all steps
      for (var i = 0; i < 5; i++) {
        if (this.tour.steps.length >= 0) {
          this.tour.steps.forEach((step) => {
            this.tour.removeStep(step.id);
            // step.destroy();
          });
        }
      }


      // Delete any steps in the list from the reload
      this.tour.steps.forEach((step) => {
        step.destroy();
      });


      //step 1 = welcome
      if (onboardingStep == 1) {
        this.tour.addStep({
          title: "Get Started!",
          text: `Welcome to Refine My Data, thank you for making an account and let's upload your first file for your FREE DATA AUDIT.
                  <br/><br/>
                  To get started, please drag your .csv file to the highlighted section above or click the area to select a file to upload.`,
          attachTo: {
            element: "[data-v-step='welcome']",
            on: "bottom",
          },
          buttons: [
            {
              action() {
                return this.cancel();
              },
              classes: "shepherd-button-secondary",
              text: "Skip",
            },
            {
              action() {
                return this.complete();
                // return this.next();
              },
              text: "OK",
            },
          ],
          id: "step-1",
        });
      }

      // onboarding step 2  = importing under process
      if (onboardingStep == 2) {
        this.currentTasks.forEach((record) => {
          if (record.stage == "Started") {
            this.tour.addStep({
              title: "Importing File",
              text: `Your file is now being imported into our secure environment. This will take a few minutes to complete whilst we perform our automated security and formatting checks.`,
              attachTo: {
                element: "[data-v-step='" + record.id + "']",
                on: "right",
              },
              buttons: [
                {
                  action() {
                    return this.cancel();
                  },
                  classes: "shepherd-button-secondary",
                  text: "Skip",
                },
                {
                  action() {
                    return this.complete();
                    // return this.next();
                  },
                  text: "OK",
                },
              ],
              id: "step-2",
            });
          }
        });
      }

      // onboarding step 2  = imported file list
      if (onboardingStep == 3) {
        this.currentTasks.forEach((record) => {
          if (record.stage == "Matched") {
            this.tour.addStep({
              title: "Lets map your file",
              text: `Before we can process your data and provide your free audit, we require you to map your data to the correct fields.
                      <br/><br/>
                      To do this, please click the highlighted area to load up the file mapping tool.`,
              attachTo: {
                element: "[data-v-step='" + record.id + "']",
                on: "right",
              },
              buttons: [
                {
                  action() {
                    return this.cancel();
                  },
                  classes: "shepherd-button-secondary",
                  text: "Skip",
                },
                {
                  action() {
                    return this.complete();
                    // return this.next();
                  },
                  text: "OK",
                },
              ],
              id: "step-3",
            });
          }
        });
      }

      // onboarding step 4 processing file list
      if (onboardingStep == 5) {
        this.currentTasks.forEach((record) => {
          if (record.stage == "Processing") {
            this.tour.addStep({
              title: "File is ready to be processed.",
              text: `Your data has been successfully loaded and is now in the queue to be processed. Data cleansing can take a few hours depending on popularity and file size, we will send you an email once the data has been processed and ready for you to view your free data report.`,
              attachTo: {
                element: "[data-v-step='" + record.id + "']",
                on: "right",
              },
              buttons: [
                {
                  action() {
                    return this.cancel();
                  },
                  classes: "shepherd-button-secondary",
                  text: "Skip",
                },
                {
                  action() {
                    return this.complete();
                    // return this.next();
                  },
                  text: "OK",
                },
              ],
              id: "step-4",
            });
          }
        });
      }

      // process finished file
      if (onboardingStep == 6) {
        this.currentTasks.forEach((record) => {
          if (record.stage == "Finished" ||
            record.progress_status == "Download Ready") {

            this.tour.addStep({
              title: "Your Free Data Report",
              text: `Congratulations, your data has been processed successfully and your free data report is now ready. You will be able to see where we have improved your data and other general statistics regarding the file you have uploaded.`,
              attachTo: {
                element: "[data-v-step='" + record.id + "']",
                on: "right",
              },
              buttons: [
                {
                  action() {
                    return this.cancel();
                  },
                  classes: "shepherd-button-secondary",
                  text: "Skip",
                },
                {
                  action() {
                    return this.complete();
                    // return this.next();
                  },
                  text: "OK",
                },
              ],
              id: "step-5",
            });
          }
        });
      }

      //event assign

      if (onboarding_flag === true && this.tour.steps.length > 0) {

        this.tour.on("cancel", this.turnOffTour);
        this.tour.on("complete", this.tourComplete);

        this.tour.start();
      }
    },
    uploadFinished() {

      Swal.fire({
        title: "Your data has been uploaded",
        confirmButtonClass: "btn btn-secondary",
      }).then(() => {
        // window.location.reload();
        this.refreshScreen();
      });
    },
    pollData() {
      this.polling = setInterval(() => {
        // this.method();
        // if(this.tour.steps.length >=0 ){
        //   this.tour.off("cancel");
        //   this.tour.off("complete");
        //   this.tour.complete();
        // }

        // for(var i=0; i < 5; i++ ){
        //   if(this.tour.steps.length >=0 ){
        //     this.tour.steps.forEach((step) => {
        //       this.tour.removeStep(step.id);
        //         // step.destroy();
        //     });
        //   }
        // }

        this.$store.dispatch(VERIFY_AUTH);
        var triggerFlag = this.$store.getters.currentUser.userData.triggerflag;
        if (triggerFlag == "Y") {

          // call tagclick, get data
          this.tagclick("triggerflag");

          //event fire off
          this.tour.off("cancel");
          this.tour.off("complete");

          // remove all steps
          for (var i = 0; i < 5; i++) {
            if (this.tour.steps.length >= 0) {
              this.tour.steps.forEach((step) => {
                this.tour.removeStep(step.id);
                // step.destroy();
              });
            }
          }
          // setTimeout(() => { this.startTour(); }, 500);
          this.startTour();

        }

        var value = new Date();
        this.$emit("RefreshValue", value);
        this.$emit('notif-refresh', value);

        // this.tour.complete();


        // if (!this.tour.isActive()) {
        //     this.startTour();
        // }
        // if(this.refreshTriggerValue==true){
        //     this.refreshTriggerValue=false;
        //     this.startTour();
        // }




        // this.getData(this.filter, "");
      }, 5 * 1000);     //60*1000 = 60 seconds   //10*1000 = 10 seconds       
      // intervel every 60 seconds / 1 minute
    },
    getData(filter) {
      this.$store.dispatch(VERIFY_AUTH);
      this.$store.dispatch(UPDATE_TASKS, {
        filter,
      });
    },
    updateTag(id, tag, flag) {
      this.$store
        .dispatch(UPDATE_TAG, {
          id: id,
          tag: tag,
          flag: flag,
        })
        .then(() => {
          setTimeout(() => {
            this.getData(this.filter, "");
          }, 1000);
        });
    },
    deleteTag(id, tag, flag) {
      Swal.fire({
        title: "Are you sure you want to delete your data?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        denyButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch(UPDATE_TAG, {
              id: id,
              tag: tag,
              flag: flag,
            })
            .then(() => {
              setTimeout(() => {
                this.getData(this.filter, "");
              }, 1000);
            });
        }
      });
    },
    refreshScreen() {
      //window.location.reload();
      // this.$router.push({name: 'dashboard'});

      // this.dashboard_reload++;
      // this.times = this.times + 1;
      // this.$mount();

      this.tagclick("");
      this.startTour();
      // this.method();      
    },
    tagclick(e) {
      this.filter = e;
      this.getData(this.filter, "");
      //this.startTour();
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      // Then specify how you want your dates to be formatted
      return new Intl.DateTimeFormat("en-GB", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      }).format(date);
      // {dateStyle: 'short'}
    },
    getStatusColor(item) {
      var retStr = "#416f85";
      // if (item.stage == 'Paid') { retStr = '#4b1dcb'}
      // if (item.stage == 'Finished') { retStr = '#33b985'}
      // if (item.stage == 'Matched')  { retStr = '#b98533'}
      // if (item.stage == 'Processing')  { retStr = '#b93367'}

      if (item.stage == "Archived") {
        retStr = "#b93367";
      }
      if (item.stage == "Started") {
        retStr = "#d8af83";
      }
      if (item.stage == "Paid") {
        retStr = "#0000CD";
      }
      if (item.stage == "Finished") {
        retStr = "#008000";
      }
      if (item.stage == "Matched") {
        retStr = "#2980b9";
      }
      if (item.stage == "Processing") {
        retStr = "#FF8C00";
      }

      return retStr;
    },
    getStatusIcon(item) {
      var retStr = "mdi-file-excel";

      if (item.stage == "Paid") {
        retStr = "media/svg/icons/General/File_Paid.svg";
      }
      if (item.stage == "Finished") {
        retStr = "media/svg/icons/General/File_Ready.svg";
      }
      if (item.stage == "Matched") {
        retStr = "media/svg/icons/General/File_Import.svg";
      }
      if (item.stage == "Processing") {
        retStr = "media/svg/icons/General/File_Process.svg";
      }

      return retStr;
    },
    getStatus(item) {
      var retStr = item.progress_status;

      if (
        item.progress_status == "Queued for Processing" &&
        item.stage == "Finished"
      ) {
        retStr = "Data Ready for Review";
      }
      // if (item.stfage == 'Processing') { retStr = retStr + ' (' + this.getTime(item) + ')'}

      return retStr;
    },
    getTime(item) {
      var fileSize = parseInt(item.file_size + 0.01) * 100;

      if (fileSize < 10) {
        fileSize = 10;
      }

      return "Estimated " + fileSize + " minutes to process";
    },
    myRowClickHandler(record) {
      // 'record' will be the row data from items
      // `index` will be the visible row number (available in the v-model 'shownItems')

      // this.$tours["onboarding-tour"].stop();

      var onboardingFlag = this.currentUser.userData.onboarding.substr(0, 1);
      var onboardingStep = this.currentUser.userData.onboarding.substr(2, 5);
      var heatmap = this.currentUser.userData.heatmap;
      var onboarding_flag = this.$store.getters.currentUser.userData.onboarding_flag;
      if (onboarding_flag == 1 && onboardingFlag == "N" && (onboardingStep == 3 || onboardingStep == 6)) {
        onboardingStep = parseInt(onboardingStep) + 1;

        var onboarding = onboardingFlag + "-" + onboardingStep;

        this.$store.dispatch(
          UPDATE_USER_SETTING,
          { onboarding, heatmap, onboarding_flag },
          this.$store.getters.currentUser.userData.id
        );
        this.$store.commit('setUserSetting', { onboarding: onboarding, heatmap: heatmap, onboarding_flag: onboarding_flag });
      }

      if (
        record.stage == "Matched" ||
        record.progress_status == "Import completed"
      ) {
        this.$router.push({
          name: "dashboardmatch",
          params: { id: record.id },
        });
      } else if (
        record.progress_status == "Queued for Processing" &&
        record.stage == "Finished"
      ) {
        this.$router.push({
          name: "dashboardreview",
          params: { id: record.id },
        });
      } else if ((record.progress_status == "Paid" ||
        record.progress_status == "Download Ready")
        && record.stage == "Paid") {
        this.$router.push({
          name: "dashboardreview",
          params: { id: record.id },
        });
      } else {
        this.$store.dispatch(SET_RIGHT_ASIDE, {
          diplay: true,
          record: record,
        });

        document.body.classList.add("aside-right-secondary-enabled");
      }
    },
  },
  computed: {
    ...mapGetters(["currentTasks", "currentUser", "isAuthenticated"]),
    ...mapMutations(["setUserSetting"]),
  },
};
</script>

<style>
.task-list {
  border-collapse: separate;
  border-spacing: 0px 10px;
}

.task-list thead th {
  height: 13px;
  font-size: 15px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 22px;
  letter-spacing: normal;
  text-align: left;
  /* box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.04); */
  /* border: solid 1px rgba(171, 164, 164, 0.8); */
  border-bottom: 0;
  border-left: 0;
  font-family: 'Poppins';

  position: absolute;
  background-color: #EBEBEB;
  /* border-right: 0; */
}

.tableheads::after {
  content: '';
  height: 22px;
  width: 22px;
  border-right: solid 1px rgba(184, 179, 179, 0.8);
  position: absolute !important;
  top: 11px;
  left: -17px;
  border-left: 0;

}

.task-list tbody tr {
  padding: 23px 25px 23px 28px;
  border-radius: 8px;
  /* box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.04); */
  border: solid 1px rgba(96, 94, 94, 0.8);
  /* background-color: white; */
}

.task-list tbody tr td {
  width: 97px;
  height: 23px;
  left: 68px;
  top: 19px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;

  /* identical to box height */

  /* color: #3D415B; */
  color: #3D415B;
  cursor: pointer;
}

.dashboardTagButton {
  background-color: transparent !important;
  width: 100%;
}

.dashboardTagButton button {
  background-color: transparent !important;
  border-color: transparent !important;
  padding: 0;
  font-weight: 700;
  color: #3f4254;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  width: 100%;
}

.dashboardactions .dropdown-toggle-no-caret {
  border: 0;
}

.Dashboard-HeaderRow {
  height: 90px;
}

.dashboarmapp-left-pad {
  margin-left: 60px;
}

.Dashboard-Go-Back {
  cursor: pointer;
  opacity: 0.8;
  font-size: 13px;
  line-height: 70px;
  text-align: left;
  color: #3f6f86;
}

.Dashboard-Readr-Campaign-Dataxls {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: #3f6f86;
  line-height: 70px;
}

.wordings {
  text-align: center;
  padding: 220px;
}

.wordNothing {
  text-align: center;
  padding: 220px;
}

.no_file {
  padding: 200px;
  text-align: center;
  font-weight: 500;
}

#refresh {
  width: 24px;
  height: 24px;
  margin: 15px 0px;
  margin-right: 10px;
}

.allfiles {
  /* width: 58px;
height: 22px; */


  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  /* identical to box height, or 129% */
  letter-spacing: -0.408px;

  color: #3D415B;
}

/* 
.v-step__button[data-v-54f9a632] {
    border-radius: 1rem;
    background:rgb(47,166,115) ;
    cursor: pointer;
    display: inline-block;
    font-size: 1.5rem;
    height: 3.5rem;
    width: 11rem;
    line-height: 1rem;
    outline: none;
    margin: 7px 0.5rem;
    padding: 0.35rem 0.4rem;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    white-space: nowrap;
}

.v-step[data-v-54f9a632] {
  border-radius: 3rem;
    max-width: 700px;
    width: 580px;
    max-height:350px;
    height: 217px;
    padding: 4rem;
    color:black;
} */


/* ######################## */
/* .b-table th.sortable {
  position: relative;
}
.b-table th.sortable .sort-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
} */

.table-container {
  /* position: relative; */
  /* overflow: hidden; */
  height: 330px;
  overflow-y: auto;
  display: flex;
}

.custom-b-table-class th {
  position: sticky !important;
  top: 0;
}
</style>

<style scoped>
.table.b-table>thead>tr>.table-b-table-default {
  background-color: #535151;
}

.table-body {
  scrollbar-width: thin;
  /* or none */
}

.b-table-sticky-header {
  overflow-y: auto;
  max-height: 310px;
}

.scroll-content {
  width: 100%;
  height: 100%;
}

/* Target the scrollbar */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #CCD2E3;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: #888; */
  background: #606060;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.shepherd-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
  /* identical to box height */

  color: #3D415B;
}
</style>
